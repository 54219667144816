import React from 'react';
import { HeaderProvider } from "./src/context/Header";

export const wrapRootElement = ({ element }) => (
  <HeaderProvider>{element}</HeaderProvider>
)

export const onClientEntry = () => {

  window.onload = () => {

    // new SmoothScroll(document, 120, 12);

  }

  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
  }
}
