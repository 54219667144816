import React, { createContext, useState } from "react"

const HeaderContext = createContext({
  isMenuOpen: false,
  onMenuStateChange: () => null
});

const HeaderProvider = ({ children }) => {

  const [isMenuOpen, onMenuStateChange] = useState(false);

  return (
    <HeaderContext.Provider
      value={{
        isMenuOpen,
        onMenuStateChange,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderContext
export { HeaderProvider }